import React, { useState } from "react";
import QRCode from "qrcode";
import VCard from "vcard-creator";
import { Grid } from "antd";
import {
  AutoComplete,
  Button,
  Col,
  Card,
  Form,
  Input,
  Row,
  Avatar,
  Upload,
} from "antd";
import {
  FacebookFilled,
  LinkedinFilled,
  InstagramOutlined,
  WhatsAppOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
const style = {
  background: "#0092ff",
  padding: "8px 0",
};

const { useBreakpoint } = Grid;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 8,
    },
    sm: {
      span: 5,
    },
    md: {
      span: 6,
    },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: {
      span: 14,
    },
    sm: {
      span: 14,
    },
    md: {
      span: 17,
    },
    lg: {
      span: 15,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const layout = {
  lg: {
    marginTop: 20,
    marginBottom: 20,
  },
  md: {
    marginTop: 100,
    marginBottom: 20,
  },
  sm: {
    marginTop: 56,
    marginBottom: 2,
  },
  xs: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const Homepage = () => {
  const [qrcode, setQrcode] = useState("");
  const [finish, setFinish] = useState(true);
  const [fileList, setFileList] = useState(null);

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const screens = useBreakpoint();

  const onFinish = (values) => {
    console.log("Success:", values);
    Generate();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  const Name = Form.useWatch("Name", form);
  const cNumber = Form.useWatch("cNumber", form);
  const wNumber = Form.useWatch("wNumber", form);
  const email = Form.useWatch("email", form);
  const upload = Form.useWatch("upload", form);
  const cName = Form.useWatch("cName", form);
  const cRole = Form.useWatch("cRole", form);
  const cwebsite = Form.useWatch("cwebsite", form);
  const street = Form.useWatch("street", form);
  const city = Form.useWatch("city", form);
  const state = Form.useWatch("state", form);
  const pin = Form.useWatch("pin", form);
  const country = Form.useWatch("country", form);
  const fUrl = Form.useWatch("fUrl", form);
  const lUrl = Form.useWatch("lUrl", form);
  const iUrl = Form.useWatch("iUrl", form);
  const yUrl = Form.useWatch("yUrl", form);

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };
  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const vCard = new VCard();

  vCard.addName(Name);
  vCard.addCompany(cName);
  vCard.addURL(cwebsite);
  vCard.addPhoneNumber(cNumber, "PREF;WORK");
  vCard.addPhoneNumber(wNumber);
  vCard.addPhoto(upload);
  vCard.addRole(cRole);
  vCard.addEmail(email);

  vCard.addAddress(street, city, state, country, pin);

  vCard.addURL(fUrl);
  vCard.addURL(lUrl);
  vCard.addURL(yUrl);
  vCard.addURL(iUrl);

  var card = vCard.toString();

  const dummy = `\
BEGIN:VCARD\r\n\
VERSION:3.0\r\n\
REV:${new Date().toISOString()}\r\n\
N;CHARSET=utf-8:${Name};;;\r\n\
FN;CHARSET=utf-8:${Name}\r\n\
X-SOCIALPROFILE;TYPE=facebook:${fUrl}\r\n\
X-SOCIALPROFILE;TYPE=linkedIn:${lUrl}\r\n\
X-SOCIALPROFILE;TYPE=youtube:${yUrl}\r\n\
X-SOCIALPROFILE;TYPE=instagram:${iUrl}\r\n\
ORG;CHARSET=utf-8:${cName}\r\n\
EMAIL;INTERNET:${email}\r\n\
TEL;PREF;WORK:${cNumber}\r\n\
TEL;WORK:${wNumber}\r\n\
ADR;WORK;POSTAL;CHARSET=utf-8:${street};${city};${state};${pin};${country}\r\n\
URL:${cwebsite}\r\n\
END:VCARD\r\n\
`;

  console.log(dummy);
  console.log(card);

  const onChange = function loadFile(event) {
    if (event.target.files.length > 0) {
      const file = URL.createObjectURL(event.target.files[0]);
      setFileList(file);
    }
  };

  // const normFile = (e) => {
  //   console.log("Upload event:", e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  const Generate = () => {
    QRCode.toDataURL(card, (err, res) => {
      if (err) return console.log(err);

      setQrcode(res);
    });
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#4361ee",
          height: "7vh",
        }}
      >
        {" "}
      </div>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        style={{
          padding: "2rem",
        }}
      >
        <Col
          className="gutter-row headgrid"
          xs={24}
          sm={24}
          md={0}
          lg={4}
          style={{ marginBottom: "2rem" }}
        >
          <div className="immg">
            <img src="../img1.png" className="sideimg" alt="#" />
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={20}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={17}>
              <center>
                <h2>Create Contact QR</h2>
              </center>
            </Col>
          </Row>

          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="gutter-row" xs={24} sm={24} md={15} lg={17}>
              <div>
                <div>
                  <div
                    style={{
                      borderRadius: 10,
                      backgroundColor: "white",
                      marginBottom: "2rem",
                    }}
                  >
                    <div
                      style={{
                        height: "56vh",
                        paddingTop: 30,
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        initialValues={{}}
                        scrollToFirstError
                        style={{}}
                      >
                        <Form.Item
                          label="Name"
                          style={{
                            fontweight: "regular",
                          }}
                          required
                        >
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"Name"}
                                noStyle
                                value={Name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your Name!",
                                  },
                                  {
                                    min: 5,
                                  },
                                ]}
                              >
                                <Input placeholder="your name" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          name={"email"}
                          label="E-mail"
                          value={email}
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                        >
                          <Input placeholder="email" />
                        </Form.Item>
                        <Form.Item label="Contact" required>
                          <Row gutter={8}>
                            <Col span={12}>
                              <Form.Item
                                name={"cNumber"}
                                value={cNumber}
                                noStyle
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your primary number!",
                                  },
                                  {
                                    min: 10,
                                    message: "number should be of 10 digits",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="primary number"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={"wNumber"}
                                value={wNumber}
                                noStyle
                                rules={[
                                  {
                                    message:
                                      "Please input your alternate number!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="alternate number"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>

                        {/* <Form.Item
                          name="upload"
                          value={upload}
                          label="Upload"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload name="upload">
                            <Button
                              icon={<UploadOutlined />}
                              onChange={onChange}
                              filelist={fileList}
                            >
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item> */}

                        <Form.Item label="Organization">
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"cName"}
                                noStyle
                                value={cName}
                                rules={[
                                  {
                                    message: "Please input your Organization!",
                                  },
                                ]}
                              >
                                <Input placeholder="organization" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item label="Designation" required>
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"cRole"}
                                value={cRole}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your Designation!",
                                  },
                                ]}
                              >
                                <Input placeholder="designation" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"cwebsite"}
                                noStyle
                                value={cwebsite}
                                rules={[{ message: "Please input website!" }]}
                              >
                                <AutoComplete
                                  options={websiteOptions}
                                  onChange={onWebsiteChange}
                                  placeholder="website"
                                >
                                  <Input />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item label="Address">
                          <Row gutter={8}>
                            <Col span={12}>
                              <Form.Item
                                name={"street"}
                                value={street}
                                rules={[
                                  {
                                    message: "Please input your Street!",
                                  },
                                ]}
                              >
                                <Input placeholder="street" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={"city"}
                                value={city}
                                rules={[
                                  {
                                    message: "Please input your City!",
                                  },
                                ]}
                              >
                                <Input placeholder="city" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={8}>
                            <Col span={8}>
                              <Form.Item
                                name={"state"}
                                style={{
                                  height: 35,
                                }}
                                value={state}
                                rules={[
                                  {
                                    message: "Please input your State!",
                                  },
                                ]}
                              >
                                <Input placeholder="state" />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item
                                name={"pin"}
                                style={{
                                  height: 35,
                                }}
                                value={pin}
                                rules={[
                                  {
                                    message: "Please input your pin!",
                                  },
                                ]}
                              >
                                <Input placeholder="pin" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name={"country"}
                                style={{
                                  height: 35,
                                }}
                                value={country}
                                rules={[
                                  {
                                    message: "Please input country!",
                                  },
                                ]}
                              >
                                <Input placeholder="country" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>

                        <Form.Item label="Facebook">
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"fUrl"}
                                value={fUrl}
                                noStyle
                                rules={[
                                  {
                                    message: "Please input your Facebook ID!",
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={websiteOptions}
                                  onChange={onWebsiteChange}
                                  placeholder="facebook"
                                >
                                  <Input />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item label="LinkedIn">
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"lUrl"}
                                value={lUrl}
                                noStyle
                                rules={[
                                  {
                                    message: "Please input your LinkedIn ID!",
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={websiteOptions}
                                  onChange={onWebsiteChange}
                                  placeholder="linkedin"
                                >
                                  <Input />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item label="Youtube">
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"yUrl"}
                                value={yUrl}
                                noStyle
                                rules={[
                                  {
                                    message: "Please input your Youtube ID!",
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={websiteOptions}
                                  onChange={onWebsiteChange}
                                  placeholder="youtube"
                                >
                                  <Input />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item label="Instagram">
                          <Row gutter={8}>
                            <Col span={24}>
                              <Form.Item
                                name={"iUrl"}
                                value={iUrl}
                                noStyle
                                rules={[
                                  {
                                    message: "Please input your Instagram ID!",
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={websiteOptions}
                                  onChange={onWebsiteChange}
                                  placeholder="instagram"
                                >
                                  <Input />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form>
                    </div>
                    <div
                      style={{
                        height: "7.5vh",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Form form={form} onFinish={onFinish}>
                        <Form.Item {...tailFormItemLayout}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              paddingLeft: 30,
                              paddingRight: 30,
                              marginTop: "1.2vh",
                            }}
                          >
                            <strong>Submit</strong>
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="gutter-row gen"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 9 }}
              lg={{ span: 7 }}
              style={{ marginBottom: "2rem" }}
              // lg={7}
            >
              <Row gutter={8} lg={24}>
                <Col span={24}>
                  <div>
                    {qrcode && onFinish && (
                      <>
                        <center>
                          <div
                            style={{
                              height: "33.5vh",
                              backgroundColor: "#ffffff",
                              color: "black",
                              borderRadius: 10,
                              marginBottom: 20,
                            }}
                          >
                            <img
                              src={qrcode}
                              alt="#"
                              style={{
                                width: "24vh",
                                marginTop: 15,
                              }}
                            />
                            <div className="d-flex">
                              <a
                                href={qrcode}
                                className=" d-flex"
                                download="qrcode.png"
                              >
                                <Button type="primary" htmlType="submit">
                                  <strong>Download</strong>
                                </Button>
                              </a>
                            </div>
                          </div>
                        </center>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {/* </div>
                </Col>
                <Col span={24}>
                  <div> */}
              {/* <Row> */}
              <Row>
                <Col span={24}>
                  <div>
                    <Card
                      style={{
                        backgroundColor: "#ffffff",
                        width: "100%",
                        lineHeight: "3.5rem",
                      }}
                      actions={[
                        <WhatsAppOutlined style={{ color: "green" }} />,
                        <FacebookFilled style={{ color: "blue" }} />,
                        <LinkedinFilled style={{ color: "skyblue" }} />,
                        <InstagramOutlined style={{ color: "purple" }} />,
                      ]}
                    >
                      <div>
                        <Row>
                          <Col
                            className="gutter-row"
                            xs={3}
                            sm={4}
                            md={4}
                            lg={4}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              <span>
                                <UserOutlined />
                              </span>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            style={{}}
                            xs={21}
                            sm={20}
                            md={20}
                            lg={20}
                          >
                            <div>
                              <span>{Name}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="gutter-row"
                            xs={3}
                            sm={4}
                            md={4}
                            lg={4}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              <span>
                                <MailOutlined />
                              </span>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            style={{}}
                            xs={21}
                            sm={20}
                            md={20}
                            lg={20}
                          >
                            <div>
                              <span>{email}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="gutter-row"
                            xs={3}
                            sm={4}
                            md={4}
                            lg={4}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              <span>
                                <PhoneOutlined />
                              </span>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            style={{}}
                            xs={21}
                            sm={20}
                            md={20}
                            lg={20}
                          >
                            <div>
                              <span>{cNumber}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Row
          style={{
            width: "100%",
            // paddingLeft: "2rem",
            // paddingRight: "2rem",
          }}
        >
          <Col className="gutter-row " span={24}>
            <div>
              <center>
                <img
                  src="../img2.png"
                  style={{
                    height: "15vh",
                    width: "98%",
                  }}
                />
              </center>
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Homepage;
