import "./App.css";
import "antd/dist/reset.css";
import Homepage from "./Components/homepage";

function App() {
  return (
    <>
      <Homepage />
    </>
  );
}

export default App;
